:root {
  --space-unit: 1rem; /* 1rem = 10 px*/
  --space-xxs: calc(0.4 * var(--space-unit)); /* 4px */
  --space-xs: calc(0.8 * var(--space-unit)); /* 8px */
  --space-sm: calc(1.2 * var(--space-unit)); /* 12px */
  --space-md: calc(2 * var(--space-unit)); /* 20px */
  --space-lg: calc(3.2 * var(--space-unit)); /* 32px */
  --space-xl: calc(5.2 * var(--space-unit)); /* 52px */
  --space-xxl: calc(8.4 * var(--space-unit)); /* 84px */
}
