/* --------------------------------
Colors
-------------------------------- */

:root {
  /* main colors */
  --color-primary: #fa0568;
  --color-primary-light: #fb3786; /* Tinted with 20% https://maketintsandshades.com/ */
  --color-primary-dark: #c80453; /* Shaded with 20% https://maketintsandshades.com/ */

  --color-secondary: #9752b0;
  --color-secondary-light: #ac75c0; /* Tinted with 20% https://maketintsandshades.com/ */
  --color-secondary-dark: #79428d; /* Shaded with 20% https://maketintsandshades.com/ */

  --color-accent: #dc5656;
  --color-accent-light: #e37878; /* Tinted with 20% https://maketintsandshades.com/ */
  --color-accent-dark: #b04545; /* Shaded with 20% https://maketintsandshades.com/ */

  --color-approval: #00b77d;
  --color-approval-light: #33c597; /* Tinted with 20% https://maketintsandshades.com/ */
  --color-approval-dark: #009264; /* Shaded with 20% https://maketintsandshades.com/ */

  --color-ternary: #c1c8cf;
  --color-ternary-light: #cdd3d9; /* Tinted with 20% https://maketintsandshades.com/ */
  --color-ternary-dark: #9aa0a6; /* Shaded with 20% https://maketintsandshades.com/ */

  --green: #00b77d;
  --red: #dc5656;
  --blue: #568bf4;
  --color-accent: #f5414f;
  --light-blue: #f0f5ff;
  --yellow: #fff06f;

  /* shades - generated using chroma.js - 12 steps */
  --black: #1d1d21;
  --gray-2: #332e38;
  --gray-3: #4d4653;
  --gray-4: #696071;
  --gray-5: #807788;
  --gray-6: #9990a2;
  --gray-7: #b3abba;
  --gray-8: #d4ceda;
  --gray-9: #e6e1ea;
  --white: white;

  /* buttons */
  --btn-primary-bg: var(--color-primary);
  --btn-primary-hover: var(--color-primary-dark);
  --btn-primary-active: var(--color-primary-light);
  --btn-primary-label: var(--white);

  --btn-secondary-bg: var(--color-secondary);
  --btn-secondary-hover: var(--color-secondary-dark);
  --btn-secondary-active: var(--color-secondary-light);
  --btn-secondary-label: var(--white);

  --btn-accent-bg: var(--color-accent);
  --btn-accent-hover: var(--color-accent-dark);
  --btn-accent-active: var(--color-accent-light);
  --btn-accent-label: var(--white);

  --btn-approval-bg: var(--color-approval);
  --btn-approval-hover: var(--color-approval-dark);
  --btn-approval-active: var(--color-approval-light);
  --btn-approval-label: var(--white);

  --btn-ternary-bg: var(--color-ternary);
  --btn-ternary-hover: var(--color-ternary-dark);
  --btn-ternary-active: var(--color-ternary-light);
  --btn-ternary-label: var(--white);

  --btn-disabled-bg: var(--gray-2);
  --btn-disabled-label: var(--gray-10);
}
