:root {
  --font-avalon: Avalon;
  --font-montserrat: Montserrat;

  /* set base values */
  --text-base-size: 1rem;
  --text-scale-ratio: 1.2;

  /* type scale */
  --text-xs: calc(
    var(--text-base-size) / (var(--text-scale-ratio) * var(--text-scale-ratio))
  );
  --text-sm: calc(var(--text-base-size) / var(--text-scale-ratio));
  --text-md: calc(var(--text-base-size) * var(--text-scale-ratio));
  --text-lg: calc(
    var(--text-base-size) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xl: calc(
    var(--text-base-size) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
  --text-xxl: calc(
    var(--text-base-size) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-xxxl: calc(
    var(--text-base-size) * var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio) * var(--text-scale-ratio) *
      var(--text-scale-ratio)
  );
}

.avalon--light {
  font-family: var(--font-avalon);
  font-weight: 300;
  font-style: normal;
}

.avalon--normal {
  font-family: var(--font-avalon);
  font-weight: 500;
  font-style: normal;
}

.avalon--bold {
  font-family: var(--font-avalon);
  font-weight: 700;
  font-style: normal;
}

.montserrat--light {
  font-family: var(--font-montserrat);
  font-weight: 300;
  font-style: normal;
}

.montserrat--normal {
  font-family: var(--font-montserrat);
  font-weight: 500;
  font-style: normal;
}

.montserrat--semi-bold {
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-style: normal;
}

.montserrat--bold {
  font-family: var(--font-montserrat);
  font-weight: 700;
  font-style: normal;
}

/** Avalon **/

@font-face {
  font-family: Avalon;
  font-weight: 100;
  font-style: normal;
  src: url(./fonts/avalon/Avalon-XLight-webfont.woff2) format("woff2"),
    url(./fonts/avalon/Avalon-XLight-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 200;
  font-style: normal;
  src: url("./fonts/avalon/Avalon-Book-webfont.woff2") format("woff2"),
    url("./fonts/avalon/Avalon-Book-webfont.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/avalon/Avalon-Book-webfont.woff2") format("woff2"),
    url("./fonts/avalon/Avalon-Book-webfont.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/avalon/Avalon-Medium-webfont.woff2") format("woff2"),
    url("./fonts/avalon/Avalon-Medium-webfont.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/avalon/Avalon-Medium-webfont.woff2") format("woff2"),
    url("./fonts/avalon/Avalon-Medium-webfont.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/avalon/Avalon-Demi-webfont.woff2") format("woff2"),
    url("./fonts/avalon/Avalon-Demi-webfont.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 700;
  font-style: normal;
  src: url(./fonts/avalon/Avalon-Bold-webfont.woff2) format("woff2"),
    url(./fonts/avalon/Avalon-Bold-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: Avalon;
  font-weight: 800;
  font-style: normal;
  src: url(./fonts/avalon/Avalon-Bold-webfont.woff2) format("woff2"),
    url(./fonts/avalon/Avalon-Bold-webfont.woff) format("woff");
  font-display: fallback;
}

/** Montserrat **/

/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/montserrat/montserrat-v14-latin-300.woff2) format("woff2"),
    url(./fonts/montserrat/montserrat-v14-latin-300.woff) format("woff");
  font-display: fallback;
}

/* montserrat-300italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url(./fonts/montserrat/montserrat-v14-latin-300italic.woff2)
      format("woff2"),
    url(./fonts/montserrat/montserrat-v14-latin-300italic.woff) format("woff");
  font-display: fallback;
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/montserrat/montserrat-v14-latin-regular.woff2)
      format("woff2"),
    url(./fonts/montserrat/montserrat-v14-latin-regular.woff) format("woff");
  font-display: fallback;
}

/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/montserrat/montserrat-v14-latin-italic.woff2) format("woff2"),
    url(./fonts/montserrat/montserrat-v14-latin-italic.woff) format("woff");
  font-display: fallback;
}

/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/montserrat/montserrat-v14-latin-600.woff2) format("woff2"),
    url(./fonts/montserrat/montserrat-v14-latin-600.woff) format("woff");
  font-display: fallback;
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/montserrat/montserrat-v14-latin-700.woff2) format("woff2"),
    url(./fonts/montserrat/montserrat-v14-latin-700.woff) format("woff");
  font-display: fallback;
}
